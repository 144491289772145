import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { QueryResult} from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { Venue } from "types/model/venue";
import handleRequestError from "./helpers/handleRequestError";

export const getVenuesFullList = async (): Promise<Venue[]> => {
  const response: AxiosResponse<Venue[]> = await axios.get(
    "/api/venues?type=fullList"
  );
  return response.data;
};

export default function useVenuesFullList(): QueryResult<
  Venue[],
  AxiosError<ErrorResponse>
> {
  return useQuery("venuesFullList", getVenuesFullList, {
    onError: error => {
      handleRequestError(error);
    }
  });
}
