import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import axios from "axios";

export const getFieldOptionIdsForActivitiesListing = async (
  _: unknown,
  listingPath: string
): Promise<string[]> => {
  const { data } = await axios.get(
    `/api/fields/field-option-ids-for-listing${
      listingPath ? `?listingPath=${listingPath}` : ""
    }`
  );
  return data;
};

export default function useFieldOptionIdsForActivitiesListing(
  listingPath: string
): QueryResult<string[]> {
  return useQuery(
    ["fieldsForActivitiesListing", listingPath],
    getFieldOptionIdsForActivitiesListing,
    {
      keepPreviousData: true
    }
  );
}
