import type { InfiniteQueryResult } from "react-query";
import { useInfiniteQuery } from "react-query";
import type { AxiosResponse } from "axios";
import axios from "axios";
import type { GetActivitiesListingResponse } from "types/model/activity";

export interface BasketActivitiesData {
  activityIds: string[];
}

const fetchActivitiesListing = async (
  nextPageOffset = 0,
  filtersStringified = "{}",
  listingPath
): Promise<GetActivitiesListingResponse> => {
  const response: AxiosResponse<GetActivitiesListingResponse> = await axios.get(
    `/api/activities/activities-listing?cursor=${nextPageOffset}&filters=${filtersStringified}${
      listingPath ? `&listingPath=${listingPath}` : ""
    }`
  );
  return response.data;
};

export default function useActivitiesListing(
  filtersStringified: string,
  listingPath: string
): InfiniteQueryResult<GetActivitiesListingResponse> {
  return useInfiniteQuery(
    `activitiesListing_${filtersStringified}_${listingPath}`,
    (key, nextPageOffset: number) =>
      fetchActivitiesListing(nextPageOffset, filtersStringified, listingPath),
    {
      getFetchMore: lastGroup => lastGroup.nextPageOffset
    }
  );
}
