import { Fragment, useState } from "react";
import { cn } from "utils/cn";
import { Dialog, Transition } from "@headlessui/react";
import { FunnelIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import { isEmpty, kebabCase } from "lodash";
import { FieldType } from "types/model/field";
import { useEffect } from "react";
import { FieldUsage } from "types/constants";
import useFields from "hooks/useFields";
import useFieldOptionIdsForActivitiesListing from "hooks/useFieldOptionIdsForActivitiesListing";
import useVenuesFullList from "hooks/useVenuesFullList";
import type { FieldFilters } from "types/model/field-data";
import HomeFiltersSkeleton from "./HomeFiltersSkeleton";

interface HomeFiltersProps {
  filters: FieldFilters;
  listingPath: string;
  isIframe: boolean;
  setFilters: (filters: { [key: string]: string }) => void;
}

const HomeFilters = ({
  filters,
  listingPath,
  isIframe,
  setFilters
}: HomeFiltersProps): React.ReactElement => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const fieldsQueryInfo = useFields(FieldUsage.Activity, false, false);
  const venuesListQueryInfo = useVenuesFullList();

  const fieldOptionIdsForActivitiesListingInfo =
    useFieldOptionIdsForActivitiesListing(listingPath);

  useEffect(() => {
    if (fieldOptionIdsForActivitiesListingInfo.data && !isEmpty(filters)) {
      const updatedFilters = Object.entries(filters).reduce(
        (acc, [key, value]) => {
          const isObjectId = value.match(/^[0-9a-fA-F]{24}$/);
          if (
            fieldOptionIdsForActivitiesListingInfo.data.includes(value) ||
            !isObjectId
          ) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      setFilters(updatedFilters);
    }
  }, [filters, fieldOptionIdsForActivitiesListingInfo.data]);

  const handleFilterChange = (fieldId, value) => {
    if (fieldId) {
      setFilters({
        ...filters,
        [fieldId]: value
      });
    }
  };

  const getActivityTitleFieldId = () => {
    if (fieldsQueryInfo.data) {
      const activityTitleField = fieldsQueryInfo.data.find(
        field => field.internalKey === "activity_title"
      );
      if (activityTitleField) {
        return activityTitleField._id;
      }
    }
    return null;
  };

  return (
    <div className={cn(!isIframe && "md:px-4 md:pb-6 md:pt-5")}>
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className={cn("relative z-40", !isIframe && "md:hidden")}
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filter</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Moble Filters */}
                <div className="space-y-6 p-4">
                  {fieldsQueryInfo.isLoading ||
                  venuesListQueryInfo.isLoading ||
                  fieldOptionIdsForActivitiesListingInfo.isLoading ? (
                    <p>Loading...</p>
                  ) : (
                    <>
                      {fieldsQueryInfo.data
                        .filter(field => field.internalKey === "activity_title")
                        .map(field => (
                          <div
                            key={field._id}
                            className="col-span-6 sm:col-span-3 md:col-span-2"
                          >
                            <div className="relative">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <input
                                type="text"
                                id={field._id}
                                name={field._id}
                                className="block w-full rounded-full border-gray-300 px-4 pl-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Search by title"
                                data-cy="overlay-filter-title"
                                onChange={(event): void => {
                                  handleFilterChange(
                                    field._id,
                                    event.target.value
                                  );
                                }}
                                value={filters[field._id] || ""}
                              />
                              {filters[getActivityTitleFieldId()] && (
                                <a
                                  className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                                  onClick={() =>
                                    handleFilterChange(
                                      getActivityTitleFieldId(),
                                      ""
                                    )
                                  }
                                >
                                  <XMarkIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                        ))}

                      {fieldsQueryInfo.data
                        .filter(
                          field =>
                            field.useForSiteFiltering &&
                            [
                              FieldType.SelectList,
                              FieldType.CheckboxMultiple,
                              FieldType.Venue
                            ].includes(field.type)
                        )
                        .map(field => (
                          <div key={field._id}>
                            <fieldset>
                              <label
                                htmlFor={filters[field._id]}
                                className="block text-sm font-medium text-gray-900"
                              >
                                {field.title}
                              </label>
                              <select
                                key={JSON.stringify(filters)}
                                id={filters[field._id]}
                                name={filters[field._id]}
                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                value={filters[field._id]}
                                data-cy={`overlay-filter-${kebabCase(
                                  field.title
                                )}`}
                                onChange={(event): void => {
                                  handleFilterChange(
                                    field._id,
                                    event.target.value
                                  );
                                }}
                              >
                                <option value="">Any</option>
                                {field.type === FieldType.Venue &&
                                  venuesListQueryInfo.data
                                    .filter(({ _id }) =>
                                      fieldOptionIdsForActivitiesListingInfo.data.includes(
                                        _id
                                      )
                                    )
                                    .map(({ _id, name }) => (
                                      <option key={_id} value={_id}>
                                        {name}
                                      </option>
                                    ))}
                                {(field.type === FieldType.SelectList ||
                                  field.type === FieldType.CheckboxMultiple) &&
                                  field.fieldOptions
                                    .filter(({ _id }) =>
                                      fieldOptionIdsForActivitiesListingInfo.data.includes(
                                        _id
                                      )
                                    )
                                    .map(({ _id, name }) => (
                                      <option key={_id} value={_id}>
                                        {name}
                                      </option>
                                    ))}
                              </select>
                            </fieldset>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <aside>
        <h2
          className={cn(
            "hidden",
            !isIframe && "mb-4 text-lg font-medium text-gray-900 md:block"
          )}
        >
          Filter
        </h2>

        <>
          <div
            className={cn(
              "flex justify-between gap-6",
              !isIframe && "md:hidden"
            )}
          >
            <div className="relative flex-grow">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>

              <input
                type="text"
                id={getActivityTitleFieldId()}
                name={getActivityTitleFieldId()}
                disabled={!getActivityTitleFieldId()}
                className="block w-full rounded-full border-gray-300 px-4 pl-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Search by title"
                data-cy="filter-title-sm"
                onChange={(event): void => {
                  handleFilterChange(
                    getActivityTitleFieldId(),
                    event.target.value
                  );
                }}
                value={filters[getActivityTitleFieldId()] || ""}
              />
              {filters[getActivityTitleFieldId()] && (
                <a
                  className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                  onClick={() =>
                    handleFilterChange(getActivityTitleFieldId(), "")
                  }
                >
                  <XMarkIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </a>
              )}
            </div>

            <button
              type="button"
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => setMobileFiltersOpen(true)}
            >
              <FunnelIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Filter
            </button>
          </div>

          <div className={cn("hidden", !isIframe && "md:block")}>
            <div className="space-y-6">
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  id={getActivityTitleFieldId()}
                  name={getActivityTitleFieldId()}
                  disabled={!getActivityTitleFieldId()}
                  className="block w-full rounded-full border-gray-300 px-4 pl-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Search by title"
                  data-cy="filter-title"
                  onChange={(event): void => {
                    handleFilterChange(
                      getActivityTitleFieldId(),
                      event.target.value
                    );
                  }}
                  value={filters[getActivityTitleFieldId()] || ""}
                />
                {filters[getActivityTitleFieldId()] && (
                  <a
                    className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                    onClick={() =>
                      handleFilterChange(getActivityTitleFieldId(), "")
                    }
                  >
                    <XMarkIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </a>
                )}
              </div>

              {fieldsQueryInfo.isLoading ||
              venuesListQueryInfo.isLoading ||
              fieldOptionIdsForActivitiesListingInfo.isLoading ? (
                <HomeFiltersSkeleton />
              ) : (
                <>
                  {fieldsQueryInfo.data
                    .filter(
                      field =>
                        field.useForSiteFiltering &&
                        [
                          FieldType.SelectList,
                          FieldType.CheckboxMultiple,
                          FieldType.Venue
                        ].includes(field.type)
                    )
                    .map(field => (
                      <div key={field._id}>
                        <fieldset>
                          <label
                            htmlFor={filters[field._id]}
                            className="block text-sm font-medium text-gray-900"
                          >
                            {field.title}
                          </label>
                          <select
                            key={JSON.stringify(filters)}
                            id={filters[field._id]}
                            name={filters[field._id]}
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            value={filters[field._id]}
                            data-cy={`filter-${kebabCase(field.title)}`}
                            onChange={(event): void => {
                              handleFilterChange(field._id, event.target.value);
                            }}
                          >
                            <option value="">Any</option>
                            {field.type === FieldType.Venue &&
                              venuesListQueryInfo.data
                                .filter(({ _id }) =>
                                  fieldOptionIdsForActivitiesListingInfo.data.includes(
                                    _id
                                  )
                                )
                                .map(({ _id, name }) => (
                                  <option key={_id} value={_id}>
                                    {name}
                                  </option>
                                ))}
                            {(field.type === FieldType.SelectList ||
                              field.type === FieldType.CheckboxMultiple) &&
                              field.fieldOptions
                                .filter(({ _id }) =>
                                  fieldOptionIdsForActivitiesListingInfo.data.includes(
                                    _id
                                  )
                                )
                                .map(({ _id, name }) => (
                                  <option key={_id} value={_id}>
                                    {name}
                                  </option>
                                ))}
                          </select>
                        </fieldset>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </>
      </aside>
    </div>
  );
};

export default HomeFilters;
